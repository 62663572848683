//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

 import { mapState } from 'vuex'
 import LazyHydrate from 'vue-lazy-hydration';

 export default {
   components: {
     LazyHydrate,
     recommendedVideos: () => import('~/components/home/recommended_videos.vue'),
     VideoOfTheWeek: () => import('~/components/home/VideoOfTheWeek.vue'),
     cams: () => import('~/components/home/live_cams.vue'),
     newestVideos: () => import('~/components/home/newest_videos.vue'),
     swiperFreeVideos: () => import('~/components/home/swiper_free_videos.vue'),
     categories: () => import('~/components/home/populair_categories.vue'),
     camHighlightMvH: () => import('~/components/home/cam_highlight_mvh.vue'),
     camHighlight: () => import('~/components/home/cam_highlight.vue'),
     models: () => import('~/components/home/populair_models.vue'),
     exclusiveVideos: () => import('~/components/home/exclusive_videos.vue'),
     privateItems: () => import('~/components/home/populair_private.vue'),
     spotlight: () => import('~/components/home/spotlight.vue'),
     shows: () => import('~/components/home/new_shows.vue'),
     epg: () => import('~/components/home/epg_now.vue'),
     topVideos: () => import('~/components/home/top_videos.vue'),
     plannedSexfilms: () => import('~/components/home/planned_sexfilms.vue'),
     about: () => import('~/components/about_section.vue'),
     randomVideos: () => import('~/components/home/random_videos.vue'),
     popunder: () => import('~/components/home/popunder.vue'),
     leaves: () => import('~/components/acties/leaves.vue'),
     htmlbanner: () => import('~/components/content/html5_banners/banner.vue'),
     DirectBanner: () => import('~/components/banner_direct.vue'),
     banner: () => import('~/components/banner.vue'),
     snow: () => import('~/components/acties/snowflakes.vue'),
     hearts: () => import('~/components/acties/hearts.vue')
   },
   async fetch() {
     let bannerDirectQuery = { 
       zone: 'HomeDirect',
       include: 'images',
     }
     let bannerQuery = { 
       zone: 'Home',
       include: 'images',
     }
     let pageQuery = { 
       name: 'home',
     }

     await Promise.all([ 
       this.$store.dispatch('pagesStore/getPage', pageQuery),
       this.$store.dispatch('bannerStore/getBanner', bannerDirectQuery),
       this.$store.dispatch('bannerStore/getBanner', bannerQuery)
     ])
   },
   computed: {
     ...mapState({
       pages: ({ pagesStore: {pages} }) => pages,
       cam_highlight_mvh: ({ camStore: {cam_highlight_mvh} }) => cam_highlight_mvh
     }),
   },
   head() {
     return {
       title: this.pages.home ? this.pages.home.seo[0].title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden',
       meta: [
         { hid: 'title', name: 'title', content: this.pages.home ? this.pages.home.seo[0].title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden' },
         { hid: 'og:title', name: 'og:title', content: this.pages.home ? this.pages.home.seo[0].title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden' },
         { hid: 'description', name: 'description', content: this.pages.home ? this.pages.home.seo[0].description : 'Van tiener tot milf. Meiden van Holland is de sexzender voor geile amateur sexfilms en webcamsex met Hollandse meiden.'},
         { hid: 'og:description', name: 'og:description', content: this.pages.home ? this.pages.home.seo[0].description : 'Van tiener tot milf. Meiden van Holland is de sexzender voor geile amateur sexfilms en webcamsex met Hollandse meiden.' },
         { hid: 'og:url', name: 'og:url', content: process.env.FE_URL },
         { hid: 'keywords', name: 'keywords', content: this.pages.home ? this.pages.home.seo[0].keywords : 'Meiden van Holland, sex, Hollandse sex, amateur sex, poldersex, Nederlandse sex'},
       ]
     }
   },
   beforeRouteEnter(to, from, next) {
     if (to.query.confirm) {
       next(vm => {
         vm.confirmUser(to.query.confirm);
       })
     } else if (to.query.resetcode) {
       next(vm => {
         vm.resetPswd(to.query.resetcode);
       })
     } else if (to.query.favoritePref) {
       next(vm => {
         vm.goToFavoritePref(to.query.favoritePref);
       })
     } else if (to.query.ended) {
       next(vm => {
         vm.ended(to.query.ended);
       })
     } else if (to.query.gp) {
       next(vm => {
         vm.gekkePetertje();
       })
     } else if (to.query.message) {
       next(vm => {
         vm.AccountMessage(to.query.message);
       })
     } else {
       next();
     }
   },
   methods: {
     async confirmUser(confirmCode) {
       var self = this;
       if (this.$auth.loggedIn) {
         this.$auth.logout();
         this.updateUserLoginStatus();
       }
       await this.$axios.get(this.$config.apiUrl + '/user/confirm/'+ confirmCode, {
       }).then(function (response) {
         if(!self.$store.state.auth.loggedIn) {
           self.login(response.data.data.attributes.token, 'confirm');
         }
       }).catch((error) => {
         var alertObj = {};
         alertObj.type = 'error';
         alertObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
         alertObj.href = '/info/contact';
         self.$store.commit('alertStore/setAlert', alertObj);
       })
     },
     async resetPswd(confirmCode) {
       var self = this;
       if (this.$auth.loggedIn) {
         this.$auth.logout();
         this.updateUserLoginStatus();
       }
       await this.$axios.get(this.$config.apiUrl + '/users/code/'+ confirmCode, {
       }).then(function (response) {
         if(!self.$store.state.auth.loggedIn) {
           self.login(response.data.data.attributes.token, 'resetpswd');
         } 
       }).catch((error) => {
         var alertObj = {};
         alertObj.type = 'info';
         alertObj.message = 'We kunnen je wachtwoord niet resetten. Klik op deze melding om contact op te nemen.';
         alertObj.href = '/info/contact';
         self.$store.commit('alertStore/setAlert', alertObj);
       });
     },
     async goToFavoritePref(confirmCode) {
       var self = this;
       this.$auth.logout();
       this.updateUserLoginStatus();
       await this.$axios.get(this.$config.apiUrl + '/user/confirm/'+ confirmCode, {
       }).then(function (response) {
         if(!self.$store.state.auth.loggedIn) {
           self.login(response.data.data.attributes.token, 'favoritePref');
         }
       }).catch((error) => {
         var alertObj = {};
         alertObj.type = 'error';
         alertObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
         alertObj.href = '/info/contact';
         self.$store.commit('alertStore/setAlert', alertObj);
       })
     },
     ended(actie) {
       var alertObj = {};
       alertObj.type = 'info';
       alertObj.message = 'De ' + actie + '-actie is helaas afgelopen.';
       this.$store.commit('alertStore/setAlert', alertObj);
     },
     gekkePetertje() {
       var alertObj = {};
       alertObj.type = 'info';
       alertObj.message = 'De sexfilms van Gekke Petertje zie je bij Meiden van Holland. Wil je Gekke Petertje op de voet volgen? Klik dan op deze melding voor zijn Twitteraccount.';
       alertObj.href = 'Petertje';
       alertObj.timeout = 10000;
       this.$store.commit('alertStore/setAlert', alertObj);
     },
     AccountMessage(msg) {
       var alertObj = {};
       alertObj.type = 'info';
       alertObj.message = msg;
       this.$store.commit('modalStore/setModal', 'account')
       this.$store.commit('alertStore/setAlert', alertObj);
     },
     login(token, todo) {
       var self = this;
       var refreshToken = token;
       if (todo == 'confirm') {
         this.$auth.setUserToken(token, refreshToken).then(function () {
           var alertObj = {};
           alertObj.type = 'succes';
           alertObj.message = 'Jouw registratie was een succes. We hebben je meteen ingelogd. Welkom bij Meiden van Holland!';
           self.$store.commit('alertStore/setAlert', alertObj);
           self.updateUserLoginStatus();
         })
       }
       if (todo == 'resetpswd') {
         this.$auth.setUserToken(token, refreshToken).then(function () {
           self.$router.push({
             path: '/account/gegevens-voorkeuren?resetpswd'
           })
           var alertObj = {};
           alertObj.type = 'info';
           alertObj.message = 'We hebben je wachtwoord gereset. Stel hierboven een nieuw wachtwoord in zodat je daar voortaan mee kunt inloggen.';
           self.$store.commit('alertStore/setAlert', alertObj);
           self.updateUserLoginStatus();
         })
       }
       if (todo == 'favoritePref') {
        this.$auth.setUserToken(token, refreshToken).then(function () {
         self.updateUserLoginStatus();
         self.$router.push({
           path: '/account/mijn-favorieten'
         })
       }) 
      }
    }
  }
}
